import React, { useEffect, useState } from "react";

// import TenderOne from "../"
import styled from "../tenders/style.module.css";
import img from "../../assets/images/Download.jpeg";
import Akash from "../../assets/images/Akash Kumar.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "../../styles/Gift.module.css";
import Styles from "../../styles/Tender.module.css";

import api from "../../utils/ApiMethod";

import { Heading, useMediaQuery } from "@chakra-ui/react";

import "swiper/css/navigation";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

// import required modules
// import { FreeMode, Pagination } from "swiper/modules";

// import required modules
import { Navigation } from "swiper/modules";
import { Button, Container } from "react-bootstrap";
import Breadcrumb from "../../components/common/Breadcrumb";
import GameDetailsPage from "./GameDetailsPage";
import MedalTally38th from "./MedalTally38th";
import Th38th_GameDetailsPage from "./38thGameDetailsPage";

function NationalGames() {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const [data, setData] = useState([]);
  const slidesPerView = isSmallScreen ? 1 : 3;

  const handleFetchData = async () => {
    try {
      // const data = await api.fetchData("http://localhost:5000/api/latestinformation");
      const res = await api.fetchData("/importantinformation");
      setData(res);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);


  return (
    <div  >
      <Breadcrumb
        pageName="Nation games 38"
        pageTitle="national games"
        id="#nationalGames"
        img_url="./img/uttarakhand 38th national games 1920 x400.jpg"
        mobile_banner="./img/uttarakhand 38th national games mobile veiw 576 x600.jpg"
      />
      <div className={`container`} >
        <Heading p={5} fontSize="24px">
        38वां नेशनल गेम्स 2025
        </Heading>
        {/* <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "800",
            fontSize: "18px",
            marginTop: "40px",
            textAlign: "justify",
          }}
        >
          नवीनतम जानकारी
        </h4> */}

        {/* NEW DATA NEEDED */}

        {/* <div className={`${Styles.tbls} row container`}> */}
        <div className={`${Styles.resp} col-8`}>
          <MedalTally38th />
        </div>
        <div className={`${Styles.resp} col-4`}>

          {/* <a href="https://37nationalgamesgoa.in/" target="_blank" >
              <Button
                style={{
                  color: "white",
                  fontSize: "1.5rem",
                  margin: "15px",
                  fontWeight: "bold",
                }}
              >
                अधिक जानकारी के लिए क्लिक करें ↗
              </Button>
            </a> */}

        </div>

        {/* </div> */}



        
        <div className={Styles.resp}>
          <Th38th_GameDetailsPage />
        </div>
        {/* <h4
          className="section-heading"
          style={{
            color: "black",
            fontWeight: "800",
            fontSize: "18px",
            marginTop: "40px",
            textAlign: "justify",
          }}
        >
          महत्वपूर्ण सूचना
        </h4> */}
        {/* <div className={Styles.resp} style={{ paddingBottom: "15px" }}>
          <table class="table table-striped ">
            <thead>
              <tr class="table-primary">
                <th className={`${Styles.th}`} scope="col">
                  क्र.सं.{" "}
                </th>
                <th className={`${Styles.head}`} scope="col">
                  विषय
                </th>

                <th className={`${Styles.head}`} scope="col">
                  डाउनलोड
                </th>
              </tr>
            </thead>

            {data && data?.map((item, index) => (
              <tbody>
                <tr>
                  <td scope="row">{index + 1}</td>
                  <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                    {item?.name}
                  </td>
                  <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                    <a href={item?.url} target="_blank">
                      <img className={Styles.table_image} src={img} />
                    </a>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div> */}

        {/* <div className="container" style={{width:'50%'}}>
          <img src="img/38thMedalChart.jpeg" />
        </div> */}

      </div>

    </div>
  );
}
export default NationalGames;
