import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { motion } from "framer-motion";
import api from "../../utils/ApiMethod";
import { Box, Center, Heading, Grid, Image, SimpleGrid, Text } from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

const GalleryCategoryWise = () => {
  const category1Images = [
    { name: "ISTAF Sepaktakraw World Cup 2025", img: "/img/istaf-wc-2025/sepaktakraw-1.jpg", id: "14" },
    { name: "68 National School Games Fencing", img: "/img/Fencing/fencing-1.png", id: "13" },
    { name: "Bihar State Open Esports Championship 2024", img: "/img/Esports/IMG-20250204-WA0021.jpg", id: "12" },
    { name: "68th National School Game Rugby", img: "/img/68thNationalSchoolGameRugby/IMG-20250203-WA0049.jpg", id: "11" },
    { name: "68th National School Games Cycling (Road)", img: "/img/68thNationalSchoolGamesCycling(Road)/IMG-20250203-WA0032.jpg", id: "10" },
    { name: "Bihar Women's Asian Champions Trophy Rajgir 2024", img: "/img/BWACTRajgir2024/ADIM45653.jpg", id: "9" },
    { name: "Bihar Volleyball League 2024", img: "/img/DSC04849.jpg", id: "8" },
    { name: "KSS-2023 at Gyan Bhawan", img: "/img/KSS-2023/DSC_3832.jpg", id: "1" },
    { name: "1st Bihar State Esports Open Championship", img: "/img/1stBiharSEOc/1stbSEoC.jpg", id: "2" },
    { name: "12th National School Chess Championship- 2024", img: "/img/12thNS-CHES24/P1383941.jpg", id: "3" },
    { name: "Bihar School Chess at BSSA", img: "/img/Bihar-School-Chess/DSC_1538.jpg", id: "4" },
    { name: "Bihar Women Kabaddi League", img: "/img/Bihar-Women-Kabaddi-League/JBN00221.jpg", id: "5" },
    { name: "Conclave 2.0", img: "/img/Conclave2.0/DSC_8220.jpg", id: "6" },
    { name: "Dashrath Manjhi", img: "/img/DashrathManjhi/DSC_6676.jpg", id: "7" },
    // { name: "Bihar Women Kabaddi League", img: "/img/Bihar-Women-Kabaddi-League/JBN00221.jpg", id: "8" }
  ];


  return (
    <div>
      <Breadcrumb
        pageName="Gallery"
        pageTitle="gallery"
        id="#gallery"
        img_url="./img/Gallery-desktop.jpg"
        mobile_banner="./img/Gallery-mobile.jpg"
      />
      <Heading p={5} fontSize="50px">
        तस्वीरें
      </Heading>

      <div className="container">
        <SimpleGrid columns={[1, 2, 3, 4]} spacing="20px" justifyItems="center">
          {category1Images.map((item, index) => (
            <Box key={index} w="100%" marginTop="15px">
              <Link to={`/GalleryCategoryWise/Events/${item.name}`} _hover={{ textDecoration: 'none' }}>
                <Box
                  position="relative"
                  width="100%"
                  paddingBottom="75%" // Creates a 4:3 aspect ratio
                  overflow="hidden"
                >
                  <Image
                    src={item.img}
                    alt={item.name}
                    position="absolute"
                    top="0"
                    left="0"
                    width="100%"
                    height="100%"
                    objectFit="cover"
                    transition="transform 0.5s ease"
                    _hover={{ transform: 'scale(1.2)' }}
                  />
                </Box>
                <Text textAlign="center" marginTop="10px" style={{ fontSize: "20px", fontWeight: "700" }}>
                  {item.name}
                </Text>
              </Link>
            </Box>
          ))}
        </SimpleGrid>
      </div>

    </div>
  );
};

export default GalleryCategoryWise;
