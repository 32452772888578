import React from "react";

import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import parisOlympic from "../../assets/images/paris_game_new.jpg";

import ninteenthAsian from "../../assets/images/ninteenth_asian_game_new.jpg";

import iccworldcup from "../../assets/images/icc_worldcup.jpeg";
import championsTrophy from "../../assets/images/champions-trophy.png";
import BiharWomensACT from "../../assets/images/BWACT 2024.jpg";

const InternationalEvent = () => {
  return (
    <div className="container">
      <div style={{ width: "100%", margin: "10px auto" }}>
        <div style={{ width: "98%", margin: "10px auto" }}>
          <h4
            className="section-heading"
            style={{ paddingTop: "15px", color: "black" }}
          >
            अंतर्राष्ट्रीय खेल आयोजन
          </h4>
        </div>

        <Swiper
          // install Swiper modules
          modules={[Navigation, Pagination, Scrollbar, A11y]}
          spaceBetween={30}
          slidesPerView={1}
          navigation={true}
          loop={true}
          // pagination={{ clickable: true }}
          // scrollbar={{ draggable: true }}
          // onSwiper={(swiper) => console.log(swiper)}
          // onSlideChange={() => console.log("slide change")}
          breakpoints={{
            320: {
              // width: 320,
              slidesPerView: 1,
              spaceBetween: 20,
            },

            425: {
              // width: 425,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
              // width: 640,
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 768px
            768: {
              // width: 768,
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            // 1440: {
            //   slidesPerView: 4,
            //   spaceBetween: 30,
            // },
          }}
        >

          <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a href="https://www.hockeyindia.org/news/india-crowned-champions-of-bihar-asian-champions-trophy-rajgir-2024-with-1-0-victory-over-china" target="_blank">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    <img src={BiharWomensACT} alt="Bihar Women's Asian Champions Trophy Rajgir 2024" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>बिहार महिला एशियाई चैंपियंस ट्रॉफी राजगीर 2024</h4>
                    {/* <h5>जेवलिन थ्रो और डिस्कस थ्रो </h5>
                  <h5>(2023)</h5> */}
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide>


          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a
                  href="https://ocasia.org/games/2-hangzhou-2023.html"
                  target="_blank"
                >
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    <img src={ninteenthAsian} alt="19th asian games 2023" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>19 वां एशियन गेम्स</h4>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a href="https://www.icc-cricket.com/about/events/icc-events/icc-champions-trophy" target="_blank">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    <img src={championsTrophy} alt="ICC Champions trophy 2025" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>आईसीसी चैंपियंस ट्रॉफी</h4>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide> */}
          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a href="https://www.icc-cricket.com/homepage" target="_blank">
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    <img src={iccworldcup} alt="ICC men's worldcup 2023" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>आईसीसी पुरुष वनडे विश्व कप</h4>
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide> */}
                    {/* <h5>जेवलिन थ्रो और डिस्कस थ्रो </h5>
                  <h5>(2023)</h5> */}

          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <div className="award__img" style={{width:"100%" ,height: "100%",}}>
                  <img src={worldSummer} alt="World summer game 2023" />
                </div>
                <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >
                  <h4>स्पेशल ओलंपिक वर्ल्ड समर गेम्स 2023</h4>
                  <h5>स्पेशल ओलंपिक वर्ल्ड समर गेम्स 2023 </h5>
                </div>
              </div>
            </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <div className="award__img" style={{width:"100%" ,height: "100%",}}>
                  <img src={asiaiCycling} alt="Asiai cycling champion 2023" />
                </div>
                <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >
                  <h4>एशियाई साइक्लिंग चैंपियनशिप 2023</h4>
                  <h5> </h5>
                  <h5>(2023)</h5>
                </div>
              </div>
            </div>
          </SwiperSlide> */}

          <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <a
                  href="https://olympics.com/en/olympic-games/paris-2024"
                  target="_blank"
                >
                  <div className="award__img" style={{ width: "100%", height: "100%", }}>
                    <img src={parisOlympic} alt="Paris olympic" />
                  </div>
                  <div
                    className="whatsNewCarousel__text"
                    style={{ padding: "12%" }}
                  >
                    <h4>पेरिस ओलंपिक्स 2024</h4>
                    {/* <h5> </h5>
                  <h5>(2023)</h5> */}
                  </div>
                </a>
              </div>
            </div>
          </SwiperSlide>



          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <div className="award__img" style={{width:"100%" ,height: "100%",}}>
                  <img src={parisOlympic} alt="Ninteen asaia game 2024" />
                </div>
                <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >
                  <h4>19वीं एशियाई खेल 2023</h4>
                  <h5>जेवलिन थ्रो और डिस्कस थ्रो </h5>
                  <h5>(2023)</h5>
                </div>
              </div>
            </div>
          </SwiperSlide> */}

          {/* <SwiperSlide>
            <div className="swiper-slide zoom">
              <div className="whatsNewCarousel__card">
                <div className="award__img" style={{width:"100%" ,height: "100%",}}>
                  <img
                    src={outdoorBoulse}
                    alt="World outdoor boules championship 2023"
                  />
                </div>
                <div
                  className="whatsNewCarousel__text"
                  style={{ padding: "12%" }}
                >
                  <h4>वर्ल्ड आउटडोर बाउल्स चैंपियनशिप 2023</h4>
                  <h5> </h5>
                  <h5>(2023)</h5>
                </div>
              </div>
            </div>
          </SwiperSlide> */}
        </Swiper>
      </div>
    </div>
  );
};

export default InternationalEvent;
