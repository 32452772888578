import React, { useState } from "react";
import stylesgfi from "../../pages/SGFI/NationalSchoolGames.module.css"; // Assuming you're using CSS for other styles
import Breadcrumb from "../../components/common/Breadcrumb";
import { Button, Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import {
  Box,
  Text,
  Image,
  Stack,
  Heading,
  SimpleGrid,
  SlideFade,
} from "@chakra-ui/react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react"; // Assuming Chakra UI for Tabs
import { Flex, VStack, HStack } from "@chakra-ui/react";
import ImageCarousel from "../../components/ImageCarousel";

export default function ISTAFSepakTakraw() {
  const [selectedContent, setSelectedContent] = useState("about");
  const matches = [
    {
      id: 1,
      date: "11 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 2,
      date: "11 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 3,
      date: "11 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 4,
      date: "12 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "THAILAND",
      player1Img: "/img/Mosquto.jpeg",
      player2: "JAPAN",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 5,
      date: "12 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 6,
      date: "12 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 7,
      date: "14 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "KOREA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "MALAYSIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 8,
      date: "14 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "CHINA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 9,
      date: "14 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "THAILAND",
      player1Img: "/img/Mosquto.jpeg",
      player2: "INDIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 10,
      date: "16 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "MALAYSIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "JAPAN",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 11,
      date: "16 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "KOREA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 12,
      date: "16 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "INDIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "CHINA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 13,
      date: "17 November 2024",
      time: "15:00 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "MALAYSIA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "THAILAND",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 14,
      date: "17 November 2024",
      time: "17:15 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "CHINA",
      player1Img: "/img/Mosquto.jpeg",
      player2: "KOREA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 15,
      date: "17 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024",
      player1: "JAPAN",
      player1Img: "/img/Mosquto.jpeg",
      player2: "INDIA",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 16,
      date: "19 November 2024",
      time: "14:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (5th/6th Place)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 17,
      date: "19 November 2024",
      time: "17:00 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Semi-final 1)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 18,
      date: "19 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Semi-final 2)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 19,
      date: "20 November 2024",
      time: "17:00 IST",
      tournament: "Women's Asian Champions Trophy 2024 (3rd/4th Place)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
    {
      id: 20,
      date: "20 November 2024",
      time: "19:30 IST",
      tournament: "Women's Asian Champions Trophy 2024 (Final)",
      player1: "TBC",
      player1Img: "/img/Mosquto.jpeg",
      player2: "TBC",
      player2Img: "/img/Mosquto.jpeg",
      venue: "Rajgir Sports Complex",
    },
  ];

  const [selectedMatch, setSelectedMatch] = useState(matches[0]); // Default to the first match

  // Handler to update the selected match
  const handleMatchClick = (match) => {
    setSelectedMatch(match);
  };

  const items = [
    {
      title: "Signature Hockey Stick",
      description:
        "Looking for the perfect gift for a hockey fan or a unique memento to commemorate an unforgettable game? Crafted as a tribute to this prestigious tournament, each stick is beautifully designed with high-quality materials and features autographs from top players participating in the tournament. This unique collector’s item celebrates the spirit of international competition and makes the perfect keepsake for hockey enthusiasts. Whether you're a fan or a collector, this hockey stick will be a prized addition to your collection.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Celebratory T-Shirts",
      description:
        "Take home the spirit of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive Tournament T-Shirts. These stylish, comfortable T-shirts are perfect for fans who want to show their support for their favorite teams and celebrate this prestigious tournament. Available in a range of sizes (S/M/L/XL), these T-shirts feature the tournament logo, organizer logos, and vibrant designs, making them the perfect keepsake for anyone attending the tournament. Grab Yours T-Shirts to Cheer your Team!",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Commemorative Caps",
      description:
        "Celebrate the excitement of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive Tournament Caps! These stylish caps, featuring the official tournament logo, are the perfect accessory for showing your support while keeping cool during the tournament. Whether you're cheering from the stands or strolling around town, these caps make a great keepsake and a fashionable reminder of this international tournament.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Mascot Fridge Magnet",
      description:
        "Take home a piece of the Bihar Women’s Asian Champions Trophy 2024 with our exclusive MASCOT (Fridge Magnet)! Featuring GUDIYA, the official tournament mascot, this fun and colorful magnet is a perfect keepsake to remember the excitement of the tournament. Stick it on your fridge or any magnet support surface and relive the thrilling moments of the tournament every day. It's a great collectible for hockey fans and a charming gift for friends and family.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Tournament Logo Fridge Magnet",
      description:
        "Celebrate the Bihar Women’s Asian Champions Trophy 2024 with an exclusive “Tournament Logo Fridge Magnet”. Featuring the official tournament emblem reflecting a blend of sports and heritage, this sleek and stylish magnet is a perfect way to commemorate this historic event. Add a touch of sports pride to your home or office with this collectible magnet, and keep the memory of the tournament alive every day.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "White Tournament Logo Fridge Magnet",
      description:
        "Take home a piece of the “Bihar Women’s Asian Champions Trophy 2024” with our special White Tournament Logo Fridge Magnet. Featuring a clean and sleek white design with the official tournament logo, reflecting a blend of sports and heritage. Perfect for your fridge or any magnetic surface, this elegant keepsake is a stylish way to commemorate the tournament and support your favorite teams.",
      imgSrc: "/img/Mosquto.jpeg",
    },
    {
      title: "Mascot 'Gudiya' Soft Toy",
      description:
        "Bring home the charm of the Bihar Women’s Asian Champions Trophy 2024 with the adorable Gudiya soft toy. Inspired by the tournament’s official mascot, this cuddly soft toy is the perfect keepsake for fans of all ages. Made with soft, high-quality materials, the Gudiya toy is not only a great collector’s item but also a wonderful gift for children and hockey enthusiasts alike.",
      imgSrc: "/img/Mosquto.jpeg",
    },
  ];
  // Content data for the right side
  const contentData = {
    about: (
      <div className="card-container">
        <div className="card-body" style={{ lineHeight: "2" }}>
          <h3 className="card-title" style={{ fontSize: "22px" }}>
            पटना में सेपक टाकरा विश्व कप का आयोजन
          </h3>

          <p className="card-text">
            सेपक टाकरा वर्ल्ड कप 2025 का आयोजन पहली बार भारत के बिहार राज्य की
            राजधानी पटना में होने जा रहा है। यह भव्य अंतर्राष्ट्रीय खेल का आयोजन
            20 मार्च से 25 मार्च 2025 तक पाटलिपुत्र इनडोर स्टेडियम, कंकड़बाग,
            पटना में आयोजित होगा। इस प्रतियोगिता में 20 देशों की शीर्ष टीमें और
            खिलाड़ी हिस्सा लेंगे, जिनमें
            फ्रांस,इंडिया,न्यूजीलैंड,वियतनाम,इटली,पोलैंड,जापान, थाईलैंड,
            सिंगापुर, श्रीलंका,स्विट्जरलैंड,संयुक्त राज्य अमेरिका, नेपाल,
            ब्राजील, ईरान,चीनी ताइपे, म्यांमार, मलेशिया,इंडोनेशिया इस देश के
            खिलाड़ी इसमें शामिल हो रहे हैं। इस वर्ल्ड कप में पुरुष, महिला और
            मिक्स्ड कैटेगरी के अंतर्गत रेगु, डबल और क्वाड इवेंट्स होंगे, जो खेल
            की विविधता और टीम भावना को दर्शाएंगे।
          </p>
          <br />

          <h3 className="card-title" style={{ fontSize: "22px" }}>
            सेपक टाकरा: एक अनोखा खेल
          </h3>

          <p className="card-text">
            सेपक टाकरा एक अनूठा खेल है, जिसे 'किक वॉलीबॉल' भी कहा जाता है। यह
            खेल बैडमिंटन कोर्ट जैसे मैदान पर खेला जाता है, जिसमें खिलाड़ी पैर,
            सिर, छाती और घुटनों से गेंद को मारते हैं। सेपक टाकरा बैडमिंटन कोर्ट
            के समान मैदान में खेला जाता है। इस कोर्ट की लंबाई 13.40 मीटर और
            चौड़ाई 6.19 मीटर होती है। मैदान को एक रेखा द्वारा दो हिस्सों में
            बांटा जाता है, जिसकी मोटाई 2 सेंटीमीटर होती है। बिहार सरकार और खेल
            विभाग द्वारा खेल अवसंरचना और 'खेलो इंडिया' पहल के तहत सभी तैयारियां
            जोरों पर हैं। यह आयोजन न केवल बिहार बल्कि भारत को वैश्विक खेल
            मानचित्र पर उभारने का अवसर है। आइए, पटना में इस खेल महाकुंभ का
            हिस्सा बनें और विश्व के सर्वश्रेष्ठ सेपक टाकरा
            खिलाड़ियों को खेलते देखें।
          </p>
          <br />
        </div>
      </div>
    ),
    logoMascot: (
      <div
        style={{
          lineHeight: "2",
          padding: "20px",
          border: "1px solid #ddd",
          borderRadius: "8px",
        }}
      >
        {/* Tournament Logo Section */}
        <div style={{ marginBottom: "30px" }}>
          <h2 style={{ fontSize: "22px", marginBottom: "15px" }}>
            आधिकारिक लोगो — "सेपक टाकरा वर्ल्ड कप 2025"
          </h2>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            सेपक टाकरा वर्ल्ड कप 2025 का आधिकारिक लोगो इस भव्य आयोजन की आत्मा और
            उद्देश्य को दर्शाता है। यह लोगो भारत की ऐतिहासिक विरासत, आधुनिक खेल
            संस्कृति और वैश्विक एकता का सुंदर मिश्रण है।
          </p>
          <h5 style={{ textAlign: "justify", marginBottom: "15px" }}>
            लोगो की विशेषताएं:
          </h5>

          <Row style={{ marginBottom: "0px" }}>
            <Col md={8}>
              <ul style={{ paddingLeft: "20px", textAlign: "justify" }}>
                <li style={{ listStyle: "auto" }}>
                  मंदिर की आकृति: लोगो में भारत की धार्मिक और सांस्कृतिक धरोहर
                  को सम्मान देने के लिए मंदिर के आकार का प्रतीक शामिल है। यह
                  बिहार की ऐतिहासिक पहचान की याद दिलाता है।
                </li>
                <li style={{ listStyle: "auto" }}>
                  सेपक टाकरा की गेंद: लोगो के मध्य में बनी गेंद इस खेल की तेजी,
                  कौशल और टीमवर्क को प्रस्तुत करती है। यह दर्शाता है कि कैसे
                  खिलाड़ी संतुलन, फुर्ती और रणनीति के साथ खेलते हैं।
                </li>
                <li style={{ listStyle: "auto" }}>
                  गहरे और जीवंत रंग:
                  <ul>
                    <li style={{ listStyleType: "disc" }}>
                      बैंगनी (शक्ति और साहस){" "}
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      नारंगी (उत्साह और ऊर्जा){" "}
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      हरा (समृद्धि और विकास){" "}
                    </li>
                    <li style={{ listStyleType: "disc" }}>
                      पीला (आशा और खुशी){" "}
                    </li>
                  </ul>
                </li>
                <li style={{ listStyle: "auto" }}>
                  ये रंग भारत की विविधता और जीवन के रंगों का प्रतिनिधित्व करते
                  हैं, जो पूरे आयोजन की आत्मा है।
                </li>
                <li style={{ listStyle: "auto" }}>
                  वैश्विक प्रतियोगिता का प्रतीक: लोगो की पूरी डिजाइन यह संदेश
                  देती है कि सेपक टाकरा वर्ल्ड कप 2025 सिर्फ एक खेल प्रतियोगिता
                  नहीं, बल्कि संस्कृति, खेल और अंतरराष्ट्रीय मित्रता का संगम है।
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <img
                src="./img/Logo-Sepak-Takraw.png"
                alt="Description of the image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Col>
          </Row>
          <p style={{ textAlign: "justify" }}>
            यह लोगो न केवल खेल प्रेमियों के लिए गर्व का विषय है, बल्कि यह भारत
            की मेहमाननवाज़ी और खेल संस्कृति को भी विश्व मंच पर स्थापित करता है।
          </p>
        </div>

        {/* Mascot Section */}
        <div style={{ lineHeight: "2", marginTop: "50px" }}>
          <h2 style={{ fontSize: "22px", marginBottom: "15px" }}>शुभंकर भीम</h2>
          <p style={{ textAlign: "justify", marginBottom: "15px" }}>
            भीम सेपक टाकरा विश्व कप बिहार 2025 का आधिकारिक शुभंकर भीम बिहार के
            राजकीय पशु बाइसन ( गौड़) से प्रेरित है। भीम केवल ताकत और लचीलेपन का
            प्रतीक नहीं है,बल्कि यह मैत्रीपूर्ण व्यवहार और खेल भावना का भी
            प्रतिनिधित्व करता है। भीम सेपक टाकरा वर्ल्ड कप 2025 का आधिकारिक
            शुभंकर (Mascot) है, जो शक्ति, धैर्य, साहस और भारतीय संस्कृति का
            अद्भुत प्रतीक है।
          </p>
          <Row>
            <Col md={8}>
              {/* key symbolism */}
              <h5 style={{ textAlign: "justify" }}>
                भीम: सेपक टाकरा विश्व कप 2025 का प्रतीक
              </h5>
              <br />
              <ul style={{ textAlign: "justify", paddingLeft: "20px" }}>
                <li style={{ listStyleType: "disc" }}>
                  ताकत का खिलाड़ी: बाइसन की जबरदस्त ताकत सेपक टाकरा में जोरदार
                  किक्स की प्रेरणा देती है।
                </li>
                <li style={{ listStyleType: "disc" }}>
                  टीम का सितारा: झुंड में रहने वाला बाइसन टीम वर्क का प्रतीक है,
                  जैसे खेल में तालमेल जरूरी है।
                </li>
                <li style={{ listStyleType: "disc" }}>
                  चपलता और संतुलन: भारी शरीर के बावजूद तेज और संतुलित—सेपक टाकरा
                  के खिलाड़ियों की तरह।
                </li>
                <li style={{ listStyleType: "disc" }}>
                  धैर्य का धुरंधर: शांत और सहनशील बाइसन सिखाता है कि मुश्किल
                  शॉट्स में धैर्य ही जीत दिलाता है।
                </li>
                <li style={{ listStyleType: "disc" }}>
                  साहस का प्रतीक: बड़े सींगों वाला बाइसन चुनौतियों से नहीं डरता,
                  ठीक वैसे ही जैसे खिलाड़ी ऊँची किक्स लगाते हैं।
                </li>
                <li style={{ listStyleType: "disc" }}>
                  खेल भावना का चैंपियन: शाकाहारी और मैत्रीपूर्ण बाइसन खेल की
                  सच्ची भावना दर्शाता है।
                </li>
                <li style={{ listStyleType: "disc" }}>
                  मैदान का बादशाह: जंगल में प्रभावशाली, तो सेपक टाकरा 2025 में
                  भीम जोश भरने को तैयार!
                </li>
              </ul>
            </Col>
            <Col md={4}>
              <img
                src="./img/sepak-takraw-bheem.png"
                alt="Description of the image"
                style={{ width: "100%", height: "auto", objectFit: "cover" }}
              />
            </Col>
          </Row>
        </div>
      </div>
    ),
    matchFixtures: (
      <div className="card-container">
        <p>
          सेपक टाकरा वर्ल्ड कप 2025 का आयोजन भारत के बिहार में 20 से 25 मार्च तक
          होने जा रहा है, जो खेल प्रेमियों के लिए एक रोमांचक अवसर लेकर आया है।
          अंतरराष्ट्रीय सेपक टाकरा महासंघ (ISTAF) द्वारा आयोजित यह प्रतिष्ठित
          टूर्नामेंट दुनिया भर के शीर्ष खिलाड़ियों को एक मंच पर लाएगा। इसमें
          पुरुष और महिला वर्गों में विभिन्न इवेंट्स जैसे क्वाड्रेंट, डबल,
          मिक्स्ड, और रेगु शामिल होंगे सेपक टाकरा के प्रशंसकों के लिए यह एक
          शानदार मौका है! इस टूर्नामेंट का पूरा शेड्यूल आपको इस पेज पर मिल
          जाएगा। जिसमें विभिन्न कैटेगरी जैसे क्वाड्रंट, डबल्स, रेगु और मिक्स्ड
          इवेंट्स के मैचों की तिथि, समय और मुकाबले की जानकारी दी गई है। यहां आप
          आसानी से अपने पसंदीदा मैचों की डिटेल्स देख सकते हैं और टूर्नामेंट के
          हर रोमांचक मोमेंट का आनंद ले सकते हैं। नीचे दिए गए शेड्यूल में सभी
          मुकाबलों की पूरी जानकारी देखें और अपनी पसंदीदा टीमों को सपोर्ट करने के
          लिए तैयार हो जाएं!
        </p>
        <br />
        <div className="d-flex justify-content-center">
          <Button
            variant="primary"
            onClick={() =>
              window.open(
                // "pdf/events/ISW2025 - Bihar - Competition Schedule as of 19 March 2025 - as of 1300.pdf",
                "pdf/events/ISW2025 - Bihar - Competition Schedule as of 21 March 2025 - as of 1100.pdf",
                "_blank"
              )
            }
          >
            प्रतियोगिता कार्यक्रम देखें
          </Button>
        </div>
        <br />
        <ImageCarousel />
        {/* iframe not working on mobile browser */}
        {/* <div className="d-flex justify-content-center">
          <iframe
            src="pdf/events/ISW2025 - Bihar - Competition Schedule as of 19 March 2025 - as of 1300.pdf"
            style={{ width: "100%", height: "500px", border: "none" }}
            title="Competition Schedule"
          ></iframe>
          </div> */}
      </div>
    ),
    indianTeamDetails: (
      <div>
        <Row>
          <Col md={6}>
            <h1>महिला टीम</h1>
            <div className="d-md-none d-flex justify-content-center">
            </div>
            <div className="d-none d-md-block">
              {/* <iframe
                src="pdf/events/women-team-India-sepaktakraw.pdf"
                style={{
                  width: "100%",
                  height: "500px",
                  border: "none",
                  overflow: "hidden",
                }}
                title="Women Team India"
              ></iframe> */}
              <img
                src={"img/team-india-women-sepaktakraw.jpg"}
                alt="Women's Team"
                style={{ height: "auto", width: "100%", objectFit: "contain" }}
              />
            </div>
          </Col>
          <Col md={6}>
            <h1>पुरुष टीम</h1>
            <div className="d-md-none d-flex justify-content-center">
            </div>
            <div className="d-none d-md-block">
              {/* <iframe
                src="pdf/events/team-India-men-sepaktakraw.pdf"
                style={{
                  width: "100%",
                  height: "500px",
                  border: "none",
                  overflow: "hidden",
                }}
                title="Men Team India"
              ></iframe> */}
                <img
                  src={"img/team-india-men-sepaktakraw.jpg"}
                  alt="Women's Team"
                  style={{ height: "auto", width: "100%", objectFit: "contain" }}
                />
            </div>
          </Col>
        </Row>
      </div>
    ),
    results:(
      <ImageCarousel data={
        [
          {
            imageURL: "img/istaf-sepaktakraw-result-1.jpg",
            title: "result 1",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-2.jpg",
            title: "result 2",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-3.jpg",
            title: "result 3",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-4.jpg",
            title: "result 4",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-8.jpg",
            title: "result 5",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-7.jpg",
            title: "result 6",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-6.jpg",
            title: "result 7",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-5.jpg",
            title: "result 8",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-9.jpg",
            title: "result 9",
          },
          {
            imageURL: "img/istaf-sepaktakraw-result-10.jpg",
            title: "result 10",
          },
        ]
      }/>
    )
  };

  const formatKey = (key) => {
    // Define a mapping for specific keys to their corresponding string values
    const keyMapping = {
      about: "About",
      logoMascot: "Logo & Mascot",
      matchFixtures: "Match Fixtures",
      indianTeamDetails: "Indian Team Details",
      results: "Results",
    };

    // Return the mapped value if it exists, otherwise format the key
    return keyMapping[key];
  };

  return (
    <>
      <Breadcrumb
        pageName={ISTAFSepakTakraw}
        pageTitle={"ISTAF Sepak Takraw 2025"}
        img_url={"./img/istaf-sepak-takraw-2025-desktop.jpg"}
        mobile_banner="./img/istaf-sepak-takraw-2025-mobile.jpg"
      />
      <Container style={{ marginTop: "30px", marginBottom: "30px" }}>
        <div
          className={`container`}
          style={{
            borderRadius: "8px",
            marginTop: "15px",
            marginBottom: "5px",
          }}
        >
          <Tabs
            isManual
            variant="enclosed"
            onChange={(index) => {
              // Map the index to the corresponding content key
              const selectedKey = Object.keys(contentData)[index];
              setSelectedContent(selectedKey);
            }}
          >
            {/* Menu Tabs */}
            <TabList
              style={{
                display: "flex",
                flexWrap: "wrap", // Allow items to wrap to the next row
                gap: "5px", // Optional: Add spacing between tabs
              }}
            >
              {Object.keys(contentData).map((key, index) => (
                <Tab
                  key={key}
                  className="tabb"
                  as="b"
                  style={{
                    backgroundColor:
                      selectedContent === key ? "rgb(49 62 69)" : "#24528e", // Change background if active
                    color: "white",
                    padding: "5px 5px",
                    cursor: "pointer",
                    marginBottom: "20px",
                    // Responsive font size
                    fontSize: "12px", // Default for medium screen (md)
                  }}
                >
                  {formatKey(key)}
                </Tab>
              ))}
            </TabList>

            {/* Content Panels */}
            <TabPanels
              style={{
                borderLeft: "1px solid #cfd9de",
                borderRight: "1px solid #cfd9de",
                borderBottom: "1px solid #cfd9de",
                borderRadius: "0px 0px 5px 5px",
              }}
            >
              {Object.keys(contentData).map((key) => (
                <TabPanel key={key}>
                  {/* Render the content based on selected tab */}
                  {contentData[key]}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </div>
      </Container>
    </>
  );
}
