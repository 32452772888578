import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import api from "../../utils/ApiMethod";
import { AspectRatio } from "@chakra-ui/react";
import { API_KEY, channelID } from "../../utils/Secret";
import {
  Box,
  Center,
  Text,
  Grid,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Helmet } from "react-helmet";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const Videos = () => {
  const [data, setData] = useState([]);
  const [prev, setPrev] = useState();
  const [next, setNext] = useState();
  const [totalResults, setTotalResults] = useState(0);
  const [pageToken, setPageToken] = useState();

  const handleFetchData = async (pageToken) => {
    try {
      // const data = await api.fetchData("/videos");
      const resultsPerPage = 12;
      const info_resp = await fetch(
        `https://www.googleapis.com/youtube/v3/channels?part=contentDetails&id=${channelID}&key=${API_KEY}`
      );
      const info_data = await info_resp.json();
      const uploadID =
        info_data.items[0].contentDetails.relatedPlaylists.uploads;
      const video_resp = await fetch(
        `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&pageToken=${
          pageToken ?? ""
        }&maxResults=${resultsPerPage}&playlistId=${uploadID}&key=${API_KEY}`
      );
      const video_data = await video_resp.json();
      const nextPageToken = video_data?.nextPageToken;
      const prevPageToken = video_data?.prevPageToken;
      const totalResults = video_data?.pageInfo?.totalResults;
      setTotalResults(totalResults);
      setNext(nextPageToken);
      setPrev(prevPageToken);
      const video_list = video_data.items
        .map((v) => v.snippet)
        .map((v) => {
          return {
            key: v.position,
            eventName: v.title,
            video: `https://youtube.com/embed/${v.resourceId.videoId}`,
          };
        });
      // alert(uploadID);

      // const data = await api.fetchData("/videos");
      const data = video_list;
      // adjust obtained data in the form: {key, eventName, video}
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData(pageToken);
  }, [pageToken]);

  // Get YouTube Thumbnail
  const getThumbnailUrl = (videoUrl) => {
    // if (!videoUrl) return "";
    if (videoUrl.includes("v=")) {
      // Step 1: Remove the 'v=' part
      let newUrl = videoUrl.replace(/v=/, "");

      // Step 2: Extract the video ID
      let videoId = newUrl.split("/").pop();
      console.log(videoId, "videoId");
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    }
    if (videoUrl.includes("embed")) {
      const videoId = videoUrl.split("/").pop();
      return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
    } else {
      return videoUrl;
    }
  };

  const redirectToVideo = (videoUrl) => {
    if (videoUrl.includes("v=")) {
      let newUrl = videoUrl.replace(/v=/, "");
      return window.open(newUrl, "_blank");
    } else if (videoUrl.includes("embed")) {
      return window.open(videoUrl, "_blank");
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Bihar Sports Video | Sports In Bihar | Bihar State Sports Authority
        </title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की वीडियो गैलरी: इस पेज पर विभिन्न खेल आयोजनों, खिलाड़ियों की उपलब्धियों और समारोहों के वीडियो उपलब्ध हैं। यहां आप खेल से जुड़ी गतिविधियों की झलक देख सकते हैं।"
        />
      </Helmet>
      <Breadcrumb
        pageName="Videos"
        pageTitle="Videos"
        id="#videos"
        img_url="./img/Gallery-desktop.jpg"
        mobile_banner="./img/Gallery-mobile.jpg"
      />
      <div
        style={{
          background:
            "linear-gradient(to bottom right, #ffffff, #f0f0f0, #87CEFA, #B0E0E6)",
        }}
      >
        <Text
          fontWeight="bold"
          textTransform="uppercase"
          fontSize={["18", "42"]}
          letterSpacing="wide"
          color="teal.800"
          px={[4, 10, 20]}
          py={[2, 5, 10]}
        >
          वीडियोज
        </Text>

        <div
          style={{
            height: "4px",
            width: "100%",
            backgroundColor: " rgb(8, 44, 44)",
            paddingBottom: "3px",
          }}
        ></div>

        {data && !!data?.length && (
          <div>
            <p>
              <b>{data[0]?.key + 1}</b> -{" "}
              <b>{(data?.[data?.length - 1]?.key ?? 0) + 1}</b> of{" "}
              <b>{totalResults}</b>{" "}
            </p>
          </div>
        )}

        <Center position="relative" pt={"20px"} pb={"50px"}>
          <Grid
            templateColumns={[
              "1fr",
              "1fr 1fr",
              "1fr 1fr 1fr",
              "1fr 1fr 1fr 1fr",
            ]}
            alignItems={"center"}
          >
            {data.map((item, index) => (
              <Box
                key={index}
                pt="10%"
                p="5"
                transition="transform 0.3s ease-in-out"
                _hover={{ transform: "scale(1.1)" }}
              >
                <motion.div
                  key={index}
                  initial={{
                    opacity: 0,
                    translateX: index % 2 === 0 ? -50 : 50,
                    translateY: -50,
                  }}
                  whileInView={{ opacity: 1, translateX: 0, translateY: 0 }}
                  transition={{ duration: 0.2, delay: index * 0.1 }}
                >
                  {/* Lazy load with Intersection Observer */}
                  <div>
                    <AspectRatio maxW="560px" ratio={1} bg={"pink"}>
                      {/* Lazy load video thumbnail only when it's in view */}
                      <Image
                        src={getThumbnailUrl(item?.video)}
                        alt="Video Thumbnail"
                        objectFit="cover"
                        cursor="pointer"
                        onClick={() => redirectToVideo(item?.video)} // Redirect to full video
                      />
                    </AspectRatio>
                  </div>
                  <Text>{item?.eventName}</Text>
                </motion.div>
              </Box>
            ))}
          </Grid>
        </Center>
        {data && !!data?.length && (
          <div className="m-5">
            <p>
              <b>{data[0]?.key + 1}</b> -{" "}
              <b>{(data?.[data?.length - 1]?.key ?? 0) + 1}</b> of{" "}
              <b>{totalResults}</b>{" "}
            </p>
          </div>
        )}
        <div className="row justify-content-between">
          {prev && (
            <button
              style={{
                backgroundColor: "yellow",
                padding: "1%",
                borderRadius: 10,
                marginRight: "10px",
              }}
              onClick={() => {
                setPageToken(prev);
              }}
            >
              <ChevronLeftIcon boxSize={16} />
            </button>
          )}
          {next && (
            <button
              style={{
                backgroundColor: "orange",
                padding: "1%",
                borderRadius: 10,
              }}
              onClick={() => {
                setPageToken(next);
              }}
            >
              <ChevronRightIcon boxSize={16} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Videos;
