import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";
import { Navigation } from "swiper/modules";
import { useMediaQuery } from "@chakra-ui/react";
export default function ImageCarousel({
  data = [
    {
      imageURL: "img/istaf-sepaktakraw-fixture-14.jpg",
      title: "14th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-15.jpg",
      title: "15th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-11.jpg",
      title: "11th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-12.jpg",
      title: "12th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-13.jpg",
      title: "13th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-9.jpg",
      title: "9th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-10.jpg",
      title: "10th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-6.jpg",
      title: "6th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-7.jpg",
      title: "7th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-8.jpg",
      title: "8th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-4.jpg",
      title: "4th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-5.jpg",
      title: "5th",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-3.jpg",
      title: "first",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-1.jpg",
      title: "2nd",
    },
    {
      imageURL: "img/istaf-sepaktakraw-fixture-2.jpg",
      title: "3rd",
    },
    // {
    //     imageURL:"img/teams4.jpeg",
    //     title:"4th"
    // },
  ],
}) {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const slidesPerView = isSmallScreen ? 1 : 3;
  return (
    <>
      {!!data?.length && (
        <Swiper
          autoplay={{
            delay: 2000,
          }}
          // autoplay={{
          //   delay: 2000, // Autoplay delay in milliseconds
          //   disableOnInteraction: true, // Autoplay stops even after user interaction
          // }}
          slidesPerView={slidesPerView}
          // rewind={true}
          navigation={true}
          modules={[Navigation]}
          // modules={[FreeMode, Pagination]}
          loop={true}
          spaceBetween={10}
          bgGradient={[
            "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
            //   'linear(to-t, blue.200, teal.500)',
            //   'linear(to-b, orange.100, purple.300)',
          ]}
          className={styles.mySwiper}
          // style={{"height" : "600px", border:"20px solid lightgray", margin:"0px"}}
        >
          {data.map((elem, index) => (
            <SwiperSlide key={index}>
              <div className="swiper-slide zoom">
                <div className="whatsNewCarousel__card">
                  <div
                    className="award__img"
                    style={{ width: "100%", height: "auto" }}
                  >
                    {/* TO BE REVIEWED */}
                    <img
                      src={`${elem?.imageURL}`}
                      alt={elem?.title}
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      )}
    </>
  );
}
