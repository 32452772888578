import { SimpleGrid } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import styled from "./style.module.css";
import Container from "react-bootstrap/Container";
import api from "../../utils/ApiMethod";
import img from "../../assets/images/Download.jpeg";
import Styles from "../../styles/Tender.module.css";
import { TenderData } from "./tenderData";
import { Helmet } from "react-helmet";
const Tenders = () => {
  const [data, setData] = useState([]);

  console.log(data, "data")
  const [search, setSearch] = useState("");

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/tenders");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    // <div>
    <div className={styled.Container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Sports Tenders | Bihar State Sports Authority | BSSA</title>
        <meta
          name="description"
          content="बिहार राज्य खेल प्राधिकरण की निविदाएं: बिहार राज्य खेल प्राधिकरण विभिन्न परियोजनाओं और सेवाओं के लिए निविदाएं आमंत्रित करता है। निविदाओं से संबंधित जानकारी और विवरण प्राप्त करने के लिए वेबसाइट पर नियमित रूप से अद्यतन जानकारी उपलब्ध कराई जाती है।"
        />
      </Helmet>
      <h1 className="pt-2" style={{ marginBottom: "10px" }}>
        निविदाएं
      </h1>
      {/* input field for search the item */}
      <div class={styled.input_group}>
        <div class="form-outline">
          <input
            type="search"
            id="form1"
            class="form-control"
            placeholder="Search for tender"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <table class="table table-striped ">
        <thead>
          <tr class="table-primary">
            <th className={`${Styles.th}`} scope="col">
              क्र.सं
            </th>
            <th className={`${Styles.head}`} scope="col">
              विषय
            </th>
            <th className={`${Styles.head}`} scope="col">
              {" "}
              तिथि
            </th>
            <th className={`${Styles.head}`} scope="col">
              अंतिम तिथि
            </th>
            <th className={`${Styles.head}`} scope="col">
              डाउनलोड
            </th>
          </tr>
        </thead>
        <tbody>
          {TenderData.filter((item) =>
            item.name.toLowerCase().includes(search.toLowerCase())
          ).map((item, i) => (
            <tr>
              <th scope="row">{i + 1}</th>
              <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                {item.meetUrl ? (
                  <> {item.name}
                    <a href={item.meetUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "underline", color: "blue" }}>
                      <br /><br />{item.meetUrl}
                    </a></>
                ) : (
                  item.name
                )}
              </td>
              <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                {item.date}
              </td>

              <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                {item.expiryDate}
              </td>

              <td className={Styles.table_cell} style={{ textAlign: "left" }}>
                <a href={item.url} target="_blank">
                  <img className={Styles.table_image} src={img} />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    // </div>
  );
};

export default Tenders;
