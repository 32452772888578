import React from "react";
import { motion, useAnimation } from "framer-motion";

const NewsScroller = () => {
  return (
    <motion.div
    // initial={{ opacity: 0, scale: 0 }}
    // whileInView={{ opacity: 1, scale: 1 }}
    // // animate={{opacity:1, translateX:0}}
    // transition={{ duration: 0.5 }}
    >
      <motion.div class="col-sm-12 col-md-4 col-lg-4">
        <h4 class="section-heading mobile-heading" style={{ color: "white" }}>
          समाचार
        </h4>
        <div
          class="lastestNews-container"
          style={{
            scrollBehavior: "smooth",
            overflowY: "scroll",
            scrollbarColor: "teal",
            scrollbarWidth: "thin",
          }}
        >
          <style>
            {`
    ::-webkit-scrollbar {
      width: 15px; /* Adjust thee width of the scrollbar */
    }

    ::-webkit-scrollbar-thumb {
      background-color: #fcb606; /* Customize the thumb color */
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: darkteal; /* Customize the thumb color on hover */
    }
    `}
          </style>
          {/* TO BE REVIEWED */}
          <div class="lastestNews">
            <a
              href="https://hindi.oneindia.com/press-release/hero-asia-cup-rajgir-bihar-2025-historic-hosting-announcement-news-in-hindi-011-1259141.html"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="https://imagesvs.oneindia.com/webp/hi/img/2025/03/bihar-news-jpg-1743435882758_1743435881378-600x338.jpg"
                    alt="hero asia cup rajgir"
                    className="img-animate"

                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                  राजगीर में होगा हीरो एशिया कप: बिहार 2025, हॉकी इंडिया व खेल प्राधिकरण के बीच MoU पर हस्ताक्षर
                  </h5>
                  <p class="lastestNews__content__text">
                  ऐतिहासिक शहर राजगीर, बिहार, हीरो एशिया कप राजगीर, बिहार 2025 की मेजबानी के लिए पूरी तरह तैयार है। आज हॉकी इंडिया और बिहार राज्य खेल प्राधिकरण के बीच हुए समझौता ज्ञापन (MoU) के बाद इस आयोजन की आधिकारिक घोषणा की गई।<br/>
                  यह प्रतिष्ठित टूर्नामेंट 29 अगस्त से 7 सितंबर तक हाल ही में विकसित राजगीर हॉकी स्टेडियम में आयोजित होगा, जो भारत के खेल बुनियादी ढांचे में एक महत्वपूर्ण उपलब्धि और बिहार को वैश्विक खेल हब के रूप में उभरने का संकेत है। यह राजगीर द्वारा आयोजित दूसरा अंतरराष्ट्रीय खेल आयोजन होगा। इससे पहले, नवंबर 2024 में महिला एशियाई चैंपियंस ट्रॉफी का सफल आयोजन हुआ था, जिसमें भारत ने विजेता का खिताब जीता था।
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="lastestNews">
            <a
              href="https://beforeprint.in/news/bihar-news/patna-news/bihars-daughter-anshika-kumari-selected-for-senior-indian-archery-team-for-upcoming-archery-world-cup-2025/"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/anshika-news.png"
                    alt="anshika kumari"
                    className="img-animate"

                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                  बिहार की बेटी अंशिका कुमारी आगामी तीरंदाजी विश्व कप, 2025 के लिए सीनियर भारतीय तीरंदाजी टीम के लिए हुई चयनित
                  </h5>
                  <p class="lastestNews__content__text">
                  बिहार की बेटी अंशिका कुमारी का आगामी तीरंदाजी विश्व कप, 2025 के लिए सीनियर भारतीय तीरंदाजी टीम में हुआ है। इस बात की जानकारी देते हुए बिहार राज्य खेल प्राधिकरण के महानिदेशक सह मुख्य कार्यकारी अधिकारी रवीन्द्रण शंकरण ने बताया कि यह बिहार के लिए बहुत खुशी और गर्व की बात है, कि बिहार की बेटी अंशिका देश की प्रतिष्ठित तीरंदाजी खिलाडियों दीपिका, अंकिता और सिमरनजोत के साथ भारतीय टीम का गौरव बढ़ा रही हैं। अंशिका कुमारी का राष्ट्रीय तीरंदाजी टीम में चयन बिहार के अन्य खिलाडियों को बहुत प्रेरित और प्रोत्साहित करने वाला है।
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="lastestNews">
            <a
              href="https://www.inextlive.com/bihar/patna/bihar-won-the-girls-and-boys-team-won-the-rugby-national-title-1735477207"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/rugby-news.png"
                    alt="rugby"
                    className="img-animate"

                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                  बिहार जीता गर्ल्स और ब्वॉयज टीम ने जीता रग्बी नेशनल का खिताब
                  </h5>
                  <p class="lastestNews__content__text">
                  पाटलिपुत्र खेल परिसर में रविवार को 68वीं नेशनल स्कूल गेम्स 2024 रग्बी अंडर 17 ( बालक—बालिका) का समापन हुआ। बिहार की गर्ल्स टीम ने फाइनल में ओड़िशा को 17-00 से हराकर चैम्पियनशिप जीत लिया। वहीं बालक वर्ग में भी ओड़िशा को 17-10 से हराकर बिहार की टीम ने चैम्पियनशिप अपने नाम कर ली। महाराष्ट्र के बालक और बालिका दोनों वर्ग में तीसरे स्थान पर रहा। जबकि अंडर 19 आयुवर्ग में भी बिहार की गर्ल्स टीम 25 दिसंबर को चैंपियनशिप जीती थी और ब्वॉयज टीम रनर अप रही थी। आगे पढ़ें पूरी खबर।
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="lastestNews">
            <a
              href="#"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/news1.jpg"
                    alt="news image"
                    className="img-animate"

                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    पटना के जामिल और साक्षी ने बिहार के खाते में डाले दो और पदक
                  </h5>
                  <p class="lastestNews__content__text">
                    पटना के जामिल और साक्षी ने 36वें राष्ट्रीय खेलों में बिहार के लिए शानदार प्रदर्शन किया और दो और पदक जीतकर राज्य का नाम रोशन किया। प्रतियोगिता में जामिल ने उत्कृष्ट खेल दिखाया और पदक हासिल किया, जबकि साक्षी ने भी अपने प्रदर्शन से सबको प्रभावित किया। बिहार के खिलाड़ियों की इस सफलता पर खेल संघों और राज्य के लोगों ने खुशी जताई।
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/durga-singh-of-bihar-won-gold-medal-in-38th-national-junior-athletics-championship-2023/bh20231108083615028028387"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/DurgaSingh.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    बिहार की बेटी दुर्गा सिंह ने 38वें नेशनल जूनियर एथलेटिक्स
                    चैंपियनशिप में स्वर्ण पदक जीतकर प्रदेश का नाम रौशन किया है.
                  </h5>
                  <p class="lastestNews__content__text">
                    बिहार की बेटी दुर्गा सिंह ने 38वें नेशनल जूनियर एथलेटिक्स
                    चैंपियनशिप में स्वर्ण पदक जीतकर प्रदेश का नाम रौशन किया है.
                    दुर्गा ने अंडर 18 आयुवर्ग के 1500 मीटर दौड़ में अपना परचम
                    लहराया है. आगे पढ़ें पूरी खबर.
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.bhaskar.com/local/bihar/patna/news/grand-welcome-for-gold-medal-winner-shailesh-kumar-132103394.html"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/gold_medlist_shailesh_kumar.webp"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    शैलेश कुमार का अभिनंदन करते महानिदेशक
                  </h5>
                  <p class="lastestNews__content__text">
                    हाल ही में हांगझोऊ, चीन में आयोजित चौथे एशियन पैरा गेम्स
                    2023 में ऊंची कूद में स्वर्ण पदक विजेता रहे बिहार के जमुई
                    जिले के शैलेश कुमार के पटना वापस लौटने पर बिहार राज्य खेल
                    प्राधिकरण के महानिदेशक सह मुख्य कार्यकारी अधिकारी श्री
                    रवीन्द्रण शंकरण ने अपने आवास पर उन्हें सम्मानित किया।
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/bihar-women-rugby-team-lost-to-odisha-in-37th-national-games/bh20231028090040078078449"
              target="_blank"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/RubyGirls.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    37वें नेशनल गेम्स -2023 में बिहार की महिला रग्बी टीम ने
                    फाइनल में जीता रजत पदक
                  </h5>
                  <p class="lastestNews__content__text">
                    गोवा में चल रहे 37वें नेशनल गेम्स में बिहार की महिला रग्बी
                    टीम ने रजत पदक अपने नाम किया है। सेमीफाइनल मुकाबले में
                    महाराष्ट्र को हराकर फाइनल में जगह बनाने वाली महिला टीम को
                    ओडिशा के हाथों रोमांचक मुकाबले में हार का सामना करना पड़ा।
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/anuj-kumar-singh-and-sushant-singh-from-bihar-selected-for-training-camp-world-volleyball-championship/bh20230704123914220220314"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner ">
                <div class="lastestNews__image">
                  <img
                    src="img/vollyvall-2023.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    विश्व वॉलीबॉल चैम्पियनशिप 2023
                  </h5>
                  <p class="lastestNews__content__text">
                    बिहार के अनुज और सुशांत का भारतीय टीम के प्रशिक्षण शिविर में
                    हुआ चयन बिहार के अनुज कुमार सिंह और सुशांत सिंह का वर्ल्ड
                    वॉलीबाल चैम्पियनशिप के लिए भारतीय टीम के प्रशिक्षण शिविर में
                    चयन हुआ है.
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a
              href="https://www.etvbharat.com/hindi/bihar/state/patna/heroes-of-special-olympics-world-summer-games-in-germany-warmly-welcomed-in-patna/bh20230628225936256256078"
              class="lastestNews__link"
            >
              <div class="lastestNews__inner">
                <div class="lastestNews__image">
                  <img
                    src="img/olymic-award.webp"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    जर्मनी में स्पेशल ओलंपिक वर्ल्ड समर गेम्स के नायकों का पटना
                    में स्वागत
                  </h5>
                  <p class="lastestNews__content__text">
                    जर्मनी में स्पेशल ओलंपिक वर्ल्ड समर गेम्स के नायकों का पटना
                    में स्वागत स्पेशल ओलंपिक वर्ल्ड समर गेम्स में पदक जीत कर
                    लौटे बिहार के खिलाड़ियों का जोरदार स्वागत किया गया. एयरपोट पर
                    खिलाड़ी गजेन्द्र कुमार, सिंटू कुमार और कोच..
                  </p>
                </div>
              </div>
            </a>
          </div>

          <div class="lastestNews">
            <a href="#" class="lastestNews__link">
              <div class="lastestNews__inner">
                <div class="lastestNews__image">
                  <img
                    src="img/jobs-img.jpeg"
                    alt="news image"
                    class="img-animate"
                  />
                </div>
                <div class="lastestNews__content">
                  <h5 class="lastestNews__content__title">
                    बिहार में खिलाड़ियों की विभिन्न पदों पर सीधी बहाली, यहां
                    करें आवेदन
                  </h5>
                  <p class="lastestNews__content__text">
                    बिहार में खिलाड़ियों की अलग-अलग पदों पर सीधी बहाली होने वाली
                    है. राज्य के मुख्यमंत्री नीतीश कुमार की ओर से इसकी घोषणा
                    पहले ही की गई थी.
                  </p>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="text-center visit-btn">
          <a
            href="https://www.bhaskar.com/local/bihar/patna/news/grand-welcome-for-gold-medal-winner-shailesh-kumar-132103394.html"
            class="btn btn-default button-link"
          >
            अधिक जानकारी के लिएs <i class="bi bi-arrow-up-right-circle"></i>
          </a>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default NewsScroller;
