export const CabinetDecisionsData = [
  {
    title:
      "नालंदा जिला अंतर्गत राजगीर में निर्माणाधीन राज्य खेल अकादमी एवं अंतर्राष्ट्रीय मानक के क्रिकेट स्टेडियम के सुगम संचालन के लिए विभिन्न कोटि के कुल 33 संविदा आधारित पदों के सृजन की स्वीकृति",
    file: "pdf/cabinet-decision/33 Post sanlekh.pdf",
  },
  {
    title:
      "बिहार खेल विश्वविद्यालय राजगीर के प्रशासनिक कार्यों के प्रयोजनार्थ पदाधिकारियों एवं कर्मचारियों के कुल 31 पदों के सृजन की स्वीकृति प्रदान करने के संबंध में	",
    file: "pdf/cabinet-decision/31 Post sanlekh (1).pdf",
  },
  {
    title:
      "दिनांक 11-20 नवंबर 2024 तक राजगीर में प्रस्तावित हॉकी एशियन चैंपियनशिप ट्रॉफी 2024 के आयोजन हेतु सैद्धांतिक सहमति एवं बिहार सरकार द्वारा हॉकी इंडिया को प्रयोजन हेतु 10.00 करोड़ रुपए राशि देने की सहमति के संबंध में	",
    file: "pdf/cabinet-decision/Asian Hockey Champ. Trophoy Sankalp.pdf",
  },
  {
    title:
      "खेल विभाग के माननीय मंत्री एवं पदाधिकारियों के उपयोग के लिए निर्धारित अधिसीमा के अंतर्गत कुल 06 वाहन क्रय किए जाने की स्वीकृति के संबंध में	",
    file: "pdf/cabinet-decision/466 Post.pdf",
  },
  {
    title:
      "बिहार राज्य खेल प्राधिकरण के सुगम प्रबंध एवं संचालन हेतु मुख्यालय एवं क्षेत्रीय स्तर पर विभिन्न कोटि के कुल 301 पदों के सृजन की स्वीकृति	",
    file: "pdf/cabinet-decision/301 BSSA.pdf",
  },
];
