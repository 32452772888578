import React, { useEffect, useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import styles from "../styles/Gift.module.css";
import api from "../utils/ApiMethod";
import { useMediaQuery } from "@chakra-ui/react";

// Import Swiper styles

import "swiper/css/navigation";

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import { Navigation } from "swiper/modules";

function NewHighlight() {
  const [isSmallScreen] = useMediaQuery("(max-width: 990px)");
  const [data, setData] = useState([]);
  const [CMSdata, setCMSData] = useState([]);

  const handleFetchData = async () => {
    try {
      const data = await api.fetchData("/highlight");
      setData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFetchDataCMS = async () => {
    try {
      const data = await api.fetchData(
        "https://api.biharsports.org/api/latestinformation"
      );
      setCMSData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    handleFetchData();
    handleFetchDataCMS();
  }, []);
  const slidesPerView = isSmallScreen ? 1 : 3;
  return (
    <div id="gallery-home">
      <div className="container" style={{ paddingBottom: "20px" }}>
        <h4 className="section-heading">
          <h4 className="section-heading" style={{ color: "black" }}>
            नवीनतम जानकारी
          </h4>
        </h4>

        {CMSdata && CMSdata.length > 0 ? (
          <Swiper
            autoplay={{
              delay: 2000,
            }}
            // autoplay={{
            //   delay: 2000, // Autoplay delay in milliseconds
            //   disableOnInteraction: true, // Autoplay stops even after user interaction
            // }}
            slidesPerView={slidesPerView}
            // rewind={true}
            navigation={true}
            modules={[Navigation]}
            // modules={[FreeMode, Pagination]}
            loop={true}
            spaceBetween={10}
            bgGradient={[
              "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
              //   'linear(to-t, blue.200, teal.500)',
              //   'linear(to-b, orange.100, purple.300)',
            ]}
            className={styles.mySwiper}
          // style={{"height" : "600px", border:"20px solid lightgray", margin:"0px"}}
          >
            {CMSdata.map((elem, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-slide zoom">
                  <div className="whatsNewCarousel__card">
                    <div
                      className="award__img"
                     style={{ width: "333px", height: "333px" }}
                    >
                      {/* TO BE REVIEWED */}
                      <img
                        src={`https://api.biharsports.org/${elem?.image?.replace(
                          "public/images/",
                          "images/"
                        )}`}
                        alt={elem?.title}
                        style={{objectFit:"contain" }}
                      />
                      {/* <img   src={`http://localhost:4000/${elem?.image?.replace(
                      "public/images/",
                      "images/"
                    )}`}
                    alt={elem?.title}
                    /> */}

                      {/* <img src={`${elem.ImageUrl}`}
                      alt={elem?.title}
                    /> */}
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          <Swiper
            style={{
              "--swiper-navigation-color": "#fcb606",
              "--swiper-pagination-color": "#fcb606",
            }}
            autoplay={{
              delay: 2000,
            }}
            // autoplay={{
            //   delay: 2000, // Autoplay delay in milliseconds
            //   disableOnInteraction: true, // Autoplay stops even after user interaction
            // }}
            slidesPerView={slidesPerView}
            // rewind={true}
            navigation={true}
            modules={[Navigation]}
            // modules={[FreeMode, Pagination]}
            loop={true}
            spaceBetween={10}
            bgGradient={[
              "linear(to right,  rgba(255,255,255,1),rgba(255,255,58,0))",
            ]}
            className={`${styles.mySwiper}`}
          >
            {data.map((elem, index) => (
              <SwiperSlide>
                <div key={index} className="swiper-slide zoom">
                  <div className="whatsNewCarousel__card">
                    <div
                      className="award__img"
                      style={{ width: "100%", height: "100%" }}
                    >
                      <img src={elem.ImageUrl} alt="Chandan" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}
export default NewHighlight;
